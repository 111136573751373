import { parseFixed } from '@ethersproject/bignumber'
import { Fraction, JSBI, BigintIsh, Rounding } from '@/assets/utils/fraction'

const useFractionConverter = () => {

  const convertToReadable = (value: BigintIsh, decimals: BigintIsh, significant: number) => {
    return new Fraction(JSBI.BigInt(value), parseFixed('1', decimals)).toSignificant(significant, {}, Rounding.ROUND_HALF_UP)
  }

  const convertToDollar = (value: BigintIsh, price: BigintIsh, significant: number) => {
    if (!value || !price) return 0
    return new Fraction(JSBI.multiply(JSBI.BigInt(value), JSBI.BigInt(price)), parseFixed('1', 36)).toSignificant(significant, {}, Rounding.ROUND_HALF_UP)
  }

  return {
    convertToReadable,
    convertToDollar
  }
}

export default useFractionConverter
